import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c('statistics-card-with-images',{staticClass:"fill-height",attrs:{"icon":_vm.ratingsOptions.icon,"avatar":_vm.ratingsOptions.avatar,"avatar-width":_vm.ratingsOptions.avatarWidth,"chip-color":_vm.ratingsOptions.chipColor,"chip-text":_vm.ratingsOptions.chipText,"statistics":_vm.totalAmountList,"stat-title":_vm.ratingsOptions.statTitle}})],1),_c(VCol,{attrs:{"cols":"12","sm":"9"}},[_c(VCard,{staticClass:"fill-height"},[_c(VCardTitle,[_vm._v("Suchen")]),_c(VCardText,[_c('div',{staticClass:"d-flex align-center pb-5"},[_c(VTextField,{staticClass:"channel-list-search me-3",attrs:{"dense":"","outlined":"","clearable":"","hide-details":"","placeholder":"Bezeichnung suchen"},model:{value:(_vm.filter.searchString),callback:function ($$v) {_vm.$set(_vm.filter, "searchString", $$v)},expression:"filter.searchString"}})],1)])],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listAttributes,"options":_vm.options,"server-items-length":_vm.totalAmountList,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loading,"search":_vm.filter.searchString,"no-data-text":"Keine Einträge vorhanden","no-results-text":"Ihre Suche ergab keinen Treffer","footer-props":{
          itemsPerPageText: 'Anzahl pro Seite',
          itemsPerPageAllText: 'Alle',
          pageText: '{0}-{1} von {2}',
          itemsPerPageOptions: [5,10,25,50,-1]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Bezeichnung","dense":"","hide-details":"","outlined":""},model:{value:(_vm.editItem.anzeigeName),callback:function ($$v) {_vm.$set(_vm.editItem, "anzeigeName", $$v)},expression:"editItem.anzeigeName"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.editValidationError},on:{"click":function($event){return _vm.editAttributeConfirm()}}},[_vm._v(" Speichern ")]),_c(VBtn,{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.closeEdit()}}},[_vm._v(" Abbrechen ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editAttribute( item )}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }